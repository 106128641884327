.content_style h1,
.content_style h2,
.content_style h3,
.content_style h4,
.content_style h5 {
  margin-bottom: 15px;
}
.content_style p {
  margin-bottom: 15px;
}
.content_style ul {
  margin-bottom: 15px;
}
.content_style ul li {
  padding: 2px 0 2px 15px;
  list-style: none;
  background: url("/images/list_style.jpg") no-repeat top 12px left;
}
.content_style ol {
  margin-left: 20px;
  margin-bottom: 15px;
  counter-reset: item;
}
.content_style ol li {
  padding: 2px 0 2px 10px;
  text-indent: -20px;
  list-style-position: inside;
  list-style-type: none;
  counter-increment: item;
}
.content_style ol li:before {
  display: inline-block;
  width: 1em;
  padding-right: 15px;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}
.content_style table {
  width: 100%;
  padding: 0;
  table-layout: fixed;
  border: none;
  border-collapse: collapse;
}
.content_style th,
.content_style td {
  text-align: center;
}
.content_style tr:nth-child(odd) td {
  background-color: #f5f5f5;
}
.content_style tr:nth-child(even) {
  background-color: #ddd;
}
.content_style th {
  padding: 15px;
  color: #ffffff;
  background-color: #e36c1b;
  border: none;
}
.content_style td {
  padding: 10px 15px;
  font-size: 14px;
}
@media screen and (min-width: 801px) {
  .content_style td {
    border-bottom: none;
  }
}
.gallery {
  margin: 20px 0;
}
.gallery .item {
  margin: 5px;
  padding: 5px;
  border: 1px solid #bebebe;
  transition: border-color 0.4s ease-in-out;
}
.gallery .item:hover {
  border: 1px solid #e36c1b;
}
table.nostyle {
  width: auto;
}
table.nostyle th,
table.nostyle td {
  text-align: left;
}
table.nostyle tr:nth-child(odd) td {
  background-color: transparent;
}
table.nostyle tr:nth-child(even) {
  background-color: transparent;
}
table.nostyle td {
  padding: 2px 10px;
}
table.nostyle td:first-child {
  padding-left: 0;
}
.two-column {
  display: grid;
  align-items: flex-start;
  gap: 30px;
}
@media only screen and (min-width: 850px) {
  .two-column {
    grid-template-columns: 320px 1fr;
  }
}
@media screen and (min-width: 800px) {
  .two-column {
    gap: 80px;
  }
}
.price-occupancy {
  gap: 40px;
}
@media only screen and (max-width: 599px) {
  .price-occupancy {
    flex-direction: column;
    gap: 0;
  }
}
.price-occupancy .icon_calendar {
  width: 80px;
  height: 60px;
}
.price-occupancy__calendar {
  color: #e36c1b;
}
/*# sourceMappingURL=css/content-style.css.map */